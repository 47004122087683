function initServiceItems() {
    let service_items = document.querySelectorAll('.service-item');

    service_items.forEach(element => {
        if (element.classList.contains('active')) {
            element.addEventListener('mouseout', function (e) {
                this.classList.remove('active');
            });
        }

        element.addEventListener('mouseenter', function (e) {

            let parent = this.parentNode;
            let siblings = parent.querySelectorAll('.service-item');
            siblings.forEach(service_item => {
                service_item.classList.remove('active');
            });
        });
    });
}

export { initServiceItems }; 