
export function initcookies(triggerButton) {
    //setup button
    triggerButton.addEventListener('click', async function () {
        window.CookieConsent.show(true);
    });

    window.addEventListener('cc:onConsent', onCCConsent);
    window.addEventListener('cc:onChange', onCCChange);    
}
function onCCConsent(ev){
    // detail.cookie
    console.log('cookie consented');
    const features = ev.detail.cookie.categories;

    if (features.includes('experience')) {
        console.log('activate external experiences');
    }
    else {
        console.log('deactivate external experiences');
    }
}
function onCCChange(ev){
    /**
     * detail.cookie
     * detail.changedCategories
     * detail.changedServices
     */
    console.log('cookie changed');
    const features = ev.detail.cookie.categories;

    if (features.includes('experience')) {
        console.log('activate external experiences');
    }
    else {
        console.log('deactivate external experiences');
    }
}

export function resetCookieConsent() {
    window.CookieConsent.reset();
    window.removeEventListener('cc:onConsent', onCCConsent);
    window.removeEventListener('cc:onChange', onCCChange);  
}

